import { RestApiService } from "src/app/services/rest-api.service";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class SettingsService extends RestApiService {
  constructor(public http: HttpClient) {
    super(http);
  }

  addHotelFacility(hotelId, submitdata){
    return this.http
    .put<any>(this.apiURL + `/settings/hotel/`+hotelId+`/facility/add`, submitdata)
    .pipe();
  }


  getHotelSettings(hotelId){
    return this.http.get<any>(this.apiURL + `/settings/hotel/`+hotelId).pipe();
  }






  ///////////

  getSettingsAccompte() {
    return this.http.get<any>(this.apiURL + `/settings/me`).pipe();
  }
  saveSettingsProfile(formdata) {
    const submitdata = {
      firstname: formdata.firstname,
      lastname: formdata.lastname,
      phone: { code: "+33", iso2: "fr", number: formdata.phone },
    };
    return this.http
      .put<any>(this.apiURL + `/settings/me/update_my_profile`, submitdata)
      .pipe();
  }

  saveSettingsCompany(formdata){
    const submitdata = {
      company_name: formdata.companyname,
      company_siren: formdata.companysiren,
    };
    return this.http
    .put<any>(this.apiURL + `/settings/me/update_my_company`, submitdata)
    .pipe();
  }

  saveSettingsShippingAddress(formdata){
    const submitdata = formdata;
    return this.http
    .put<any>(this.apiURL + `/settings/me/update_my_shipping_address`, submitdata)
    .pipe();
  }

  saveSettingsPassword(formdata){
    const submitdata = {
      current_password: formdata.currentpassword,
      new_password: formdata.newpassword,
    };
    return this.http
    .put<any>(this.apiURL + `/settings/me/update_my_password`, submitdata)
    .pipe();
  }
}
