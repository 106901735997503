import { RestApiService } from './rest-api.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PaymentService extends RestApiService{

  constructor(public http: HttpClient) {
    super(http);
  }

  createSucceededPayment(formdata) {
    return this.http
      .post<any>(this.apiURL + `/payment/payment_succeeded`, formdata)
      .pipe();
  }
}
