import { SavedValetboyTableModule } from "./eg/valetboy/valetboy-list/saved-valetboy.module";
import { SetStorageService } from "./services/localstorage.service";
import { PaymentService } from "./services/payment.service";
import { QuoteService } from "./services/quote.service";
import { UserService } from "./services/user.service";
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { CustomLayoutComponent } from "./custom-layout/custom-layout.component";
import { AuthGuardAdmin } from "./services/auth-guard-admin.service";
import { AuthenticationService } from "./services/auth.service";
import { SettingsService } from "./services/eg/settings.service";
import { ImageUploadModule } from "angular2-image-upload";
import { AuthGuardUser } from "./services/auth-guard-user.service";
import { HotelOneComponent } from "./eg/hotels/hotel-one/hotel-one.component";

const routes: Routes = [
  {
    path: "auth/login",
    loadChildren: () =>
      import("./auth/login/login.module").then((m) => m.LoginModule),
  },
  {
    path: "auth/register",
    loadChildren: () =>
      import("./auth/register/register.module").then((m) => m.RegisterModule),
  },
  {
    path: "dashboard",
    component: CustomLayoutComponent,
    canActivate: [AuthGuardAdmin],
    children: [
      {
        path: "my-profile",
        loadChildren: () =>
          import("./pages/dashboard/dashboard.module").then(
            (m) => m.DashboardModule
          ),
      },
      {
        path: "checkins",
        loadChildren: () =>
          import("./eg/checkins/saved-checkins.module").then(
            (m) => m.SavedCheckinsTableModule
          ),
      },
      {
        path: "requests",
        loadChildren: () =>
          import("./eg/requests/request.module").then((m) => m.RequestModule),
      },
      {
        path: "valetboys",
        loadChildren: () =>
          import("./eg/valetboy/valetboy-list/saved-valetboy.module").then(
            (m) => m.SavedValetboyTableModule
          ),
      },
      {
        path: "valetboy/:valetboyId",
        loadChildren: () =>
          import("./eg/valetboy/valetboy-form/form-valetboy.module").then(
            (m) => m.FormValetboyModule
          ),
      },
      {
        path: "settings",
        loadChildren: () =>
          import("./eg/settings/settings.module").then((m) => m.SettingsModule),
      },
      {
        path: "**",
        redirectTo: "checkins",
        pathMatch: "full",
      },
    ],
  },
  {
    path: "admin",
    component: CustomLayoutComponent,
    canActivate: [AuthGuardAdmin],
    children: [
      {
        path: "hotels",
        loadChildren: () =>
          import("./eg/hotels/saved-hotels.module").then(
            (m) => m.SavedHotelsTableModule
          ),
      },
      {
        path: "**",
        redirectTo: "hotels",
        pathMatch: "full",
      },
    ],
  },
  {
    path: "**",
    redirectTo: "auth/login",
    pathMatch: "full",
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      //preloadingStrategy: PreloadAllModules,
      scrollPositionRestoration: "enabled",
      relativeLinkResolution: "corrected",
      anchorScrolling: "enabled",
    }),
    ImageUploadModule.forRoot(),
  ],
  exports: [RouterModule],
  providers: [
    AuthGuardAdmin,
    AuthenticationService,
    UserService,
    QuoteService,
    PaymentService,
    SettingsService,
    SetStorageService,
  ],
})
export class AppRoutingModule {}
