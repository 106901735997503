import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from "@angular/core";
import { MenuItem } from "../interfaces/menu-item.interface";
import { trackById } from "../../../../utils/track-by";
import { PopoverRef } from "../../../../components/popover/popover-ref";
import { AuthenticationService } from "src/app/services/auth.service";
import { User } from "src/app/models/user";

export interface OnlineStatus {
  id: "online" | "away" | "dnd" | "offline";
  label: string;
  icon: string;
  colorClass: string;
}

@Component({
  selector: "vex-toolbar-user-dropdown",
  templateUrl: "./toolbar-user-dropdown.component.html",
  styleUrls: ["./toolbar-user-dropdown.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToolbarUserDropdownComponent implements OnInit {
  items: MenuItem[] = [
    {
      id: "1",
      icon: "mat:account_circle",
      label: "My Account",
      description: "Profil",
      colorClass: "text-teal",
      route: "/dashboard/my-profile",
    },
    {
      id: "2",
      icon: "mat:account_circle",
      label: "Hotel Settings",
      description: "Hotel Settings",
      colorClass: "text-teal",
      route: "/dashboard/settings",
    },
  ];

  statuses: OnlineStatus[] = [
    {
      id: "online",
      label: "Online",
      icon: "mat:check_circle",
      colorClass: "text-green",
    },
    {
      id: "away",
      label: "Away",
      icon: "mat:access_time",
      colorClass: "text-orange",
    },
    {
      id: "dnd",
      label: "Do not disturb",
      icon: "mat:do_not_disturb",
      colorClass: "text-red",
    },
    {
      id: "offline",
      label: "Offline",
      icon: "mat:offline_bolt",
      colorClass: "text-gray",
    },
  ];

  activeStatus: OnlineStatus = this.statuses[0];

  trackById = trackById;

  currentUser: User;

  constructor(
    private cd: ChangeDetectorRef,
    private authenticationService: AuthenticationService,
    private popoverRef: PopoverRef<ToolbarUserDropdownComponent>
  ) {
    this.authenticationService.currentUser.subscribe(
      (x) => (this.currentUser = x)
    );

  }

  ngOnInit() {
    this.currentUser = this.authenticationService.currentUserValue;
    console.log(this.currentUser)

  }

  setStatus(status: OnlineStatus) {
    this.activeStatus = status;
    this.cd.markForCheck();
  }

  logout() {
    this.popoverRef.close();
    this.authenticationService.logout();
    location.reload();

  }

  close() {
    this.popoverRef.close();
  }
}
