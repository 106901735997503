import { CommonModule } from '@angular/common';
import { VcUploadComponent } from './components/vc-upload/vc-upload.component';
import { NgModule } from '@angular/core';
import { VcUploadProgressComponent } from './components/vc-upload/vc-upload-progress/vc-upload-progress.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { CustomAlertComponent } from './components/custom-alert/custom-alert.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';


@NgModule({
  declarations:[
    VcUploadComponent,
    VcUploadProgressComponent,
    CustomAlertComponent,
  ],
  imports: [
    CommonModule,
    MatProgressBarModule,
    MatTooltipModule,
    MatSelectModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,

  ],
  exports: [
    MatTooltipModule,
    MatSelectModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,

    VcUploadComponent,
    VcUploadProgressComponent,
    CustomAlertComponent
  ],
  providers: [
  ],
})
export class SharedModule {
}
