import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'vc-upload-progress',
  templateUrl: './vc-upload-progress.component.html',
  styleUrls: ['./vc-upload-progress.component.scss']
})
export class VcUploadProgressComponent implements OnInit {

  @Input() progress = 0;
  constructor() {}

  ngOnInit() {}
}
