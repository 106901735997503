import { RestApiService } from "src/app/services/rest-api.service";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class QuoteService extends RestApiService {
  constructor(public http: HttpClient) {
    super(http);
  }

  getUserQuoteById(id) {
    return this.http.get<any>(this.apiURL + `/quote/single/` + id).pipe();
  }

  getUserQuotesByFilter(filter) {
    return this.http.post<any>(this.apiURL + `/quote/filter/`, filter).pipe();
  }

  estimateUserQuotePrice(submitdata) {
    return this.http
      .post<any>(this.apiURL + `/quote/estimate`, submitdata)
      .pipe();
  }

  createUserQuote(formdata) {
    return this.http
      .post<any>(this.apiURL + `/quote/new_quote`, formdata)
      .pipe();
  }

  saveUserQuote(formdata, id) {
    return this.http
      .put<any>(this.apiURL + `/quote/save_quote/` + id, formdata)
      .pipe();
  }

  saveUserBookingQuote(formdata, id) {
    return this.http
      .put<any>(this.apiURL + `/quote/update_booking_quote/` + id, formdata)
      .pipe();
  }

  saveUserQuoteConfirmation(id) {
    return this.http
      .put<any>(this.apiURL + `/quote/confirm_quote/` + id, {})
      .pipe();
  }

  uploadQuoteFiles(formdata, id) {
    return this.http
      .post<any>(this.apiURL + `/quote/upload/` + id, formdata)
      .pipe();
  }


  deleteQuoteFile(formdata, id) {
    return this.http
      .post<any>(this.apiURL + `/quote/upload/delete/` + id, formdata)
      .pipe();
  }
}
