import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest } from 'rxjs';

@Injectable()
export class SetStorageService {

  segmentValue = new BehaviorSubject<string>(null);

  private _segmentId: string = '';
  constructor() { }

  set segmentId(value: string) {
    this._segmentId = value;
    localStorage.setItem('segmentId', value);
    this.segmentValue.next(value);
  }

  get segmentId() {
    return this._segmentId = localStorage.getItem('segmentId')
  }

}
