import { Injectable } from '@angular/core';
import { CanLoad, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardAdmin implements CanActivate {

  constructor(private authenticationService: AuthenticationService, private router: Router) {
  }

  canActivate(route, state: RouterStateSnapshot) {

    const currentUser = this.authenticationService.currentUserValue;
    const roleUser = this.authenticationService.roleUserValue;
    if (currentUser && roleUser == "ADMIN") {
        // logged in so return true
        return true;
    }

    // not logged in so redirect to login page with the return url
    this.router.navigate(['/auth/login'], { queryParams: { returnUrl: state.url } });
    return false;
  }

}
