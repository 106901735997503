import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { RestApiService } from "./rest-api.service";
import { BehaviorSubject, catchError, map, Observable } from "rxjs";
import { User } from "../models/user";
import { throwError } from "rxjs";

@Injectable({ providedIn: "root" })
export class AuthenticationService extends RestApiService {
  private currentUserSubject: BehaviorSubject<User>;
  private roleUserSubject: BehaviorSubject<string>;
  public currentUser: Observable<User>;
  public roleUser: Observable<string>;

  constructor(public http: HttpClient) {
    super(http);
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem("currentUser"))
    );
    this.currentUser = this.currentUserSubject.asObservable();
    //
    this.roleUserSubject = new BehaviorSubject<string>(
      localStorage.getItem("userRole")
    );
    this.roleUser = this.roleUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  public get roleUserValue(): string {
    return this.roleUserSubject.value;
  }

 /* login(formdata) {
    console.log(this.apiURL);
    return this.http.post<any>(this.apiURL + `/admins/login`, formdata).pipe(
      map(
        (user) => {
           console.log("user");
          console.log(user);

          let result = JSON.parse(JSON.stringify(user));
          localStorage.setItem("token", result.result.token);
          //let jwt = new JwtHelperService();
          // this.currentUser = jwt.decodeToken(localStorage.getItem("token"));
          this.isLogged = true;

          const storageObj = {
            id: result.result._id,
            uid: result.result.uid,
            email: result.result.email,
            firstname: result.result.firstname,
            lastname: result.result.firstname,
            fullname: result.result.firstname,
            token: result.result.token,
          };
          localStorage.setItem("currentUser", JSON.stringify(storageObj));
          this.currentUserSubject.next(storageObj);
          return user;
        }),
        catchError(err => {
          console.log('caught mapping error and rethrowing', err);
          console.log("err");
          console.log(err);
          return err;
      })
      )

  }*/

  /*register(formdata) {
    console.log(this.apiURL);
    return this.http.post<any>(this.apiURL + `/auth/user/register`, formdata).pipe(
      map(
        (user) => {
          console.log(user);

          let result = JSON.parse(JSON.stringify(user));
          localStorage.setItem("token", result.result.token);
          //let jwt = new JwtHelperService();
          // this.currentUser = jwt.decodeToken(localStorage.getItem("token"));
          this.isLogged = true;

          const storageObj = {
            id: result.result._id,
            uid: result.result.uid,
            email: result.result.email,
            firstname: result.result.firstname,
            lastname: result.result.firstname,
            fullname: result.result.firstname,
            token: result.result.token,
          };
          localStorage.setItem("currentUser", JSON.stringify(storageObj));
          this.currentUserSubject.next(storageObj);
          return user;
        }),
        catchError((err) => {
          return err;
        }
      )
    );
  }*/

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem("currentUser");
    localStorage.removeItem("roleUser");
    localStorage.removeItem("token");
    this.currentUserSubject.next(null);
    this.roleUserSubject.next(null);
  }

  public getToken(): string {
    return localStorage.getItem("token");
  }
  public getUserToken(): string {
    return localStorage.getItem("token");
  }
}
