import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { JwtHelperService } from "@auth0/angular-jwt";
import { Observable, throwError } from "rxjs";
import { retry, catchError, map } from "rxjs/operators";
import { Employee } from "../shared/data-types/employee";

@Injectable({
  providedIn: "root",
})
export class RestApiService {
  // Define API
  apiURL = "https://api.theeighthstar.com/api/api";
  isLogged: boolean = false;
  role: string = "";
  currentUser: any;

  constructor(public http: HttpClient) {}
  /*========================================
    CRUD Methods for consuming RESTful API
  =========================================*/
  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    }),
  };
  // HttpClient API get() method => Fetch employees list
  getEmployees(): Observable<Employee> {
    return this.http
      .get<Employee>(this.apiURL + "/employees")
      .pipe(retry(1), catchError(this.handleError));
  }

  getGooglePlaces(searchKey: string): Observable<Employee> {
    let httpHeaders = new HttpHeaders();
    httpHeaders.append("Content-Type", "application/json");

    /*httpHeaders.append(
      "Authorization",
      "Basic " + btoa("al_admin_vuac:al*admin*vuac*18")
    );*/

    const httpOptions = {
      headers: httpHeaders,
    };
    return this.http
      .get<Employee>(
        this.apiURL + "/gplace/search?searchKey=" + searchKey,
        httpOptions
      )
      .pipe(retry(1), catchError(this.handleError));
  }

  getGooglePlaceDetails(placeId: string): Observable<Employee> {
    let httpHeaders = new HttpHeaders();
    httpHeaders.append("Content-Type", "application/json");

    const httpOptions = {
      headers: httpHeaders,
    };
    return this.http
      .get<Employee>(
        this.apiURL + "/gplace/details?placeId=" + placeId,
        httpOptions
      )
      .pipe(retry(1), catchError(this.handleError));
  }

  login(loginData: any): Observable<any> {
    let httpHeaders = new HttpHeaders();
    httpHeaders.append("Content-Type", "application/json");

    const httpOptions = {
      headers: httpHeaders,
    };
    return this.http
      .post<any>(this.apiURL + `/admins/login`, loginData, httpOptions)
      .pipe(
        map((admin) => {

          let result = JSON.parse(JSON.stringify(admin));
          localStorage.setItem("token", result.token);
          localStorage.setItem("userRole", "ADMIN");
          //let jwt = new JwtHelperService();
          // this.currentUser = jwt.decodeToken(localStorage.getItem("token"));
          this.isLogged = true;

          const storageObj = {
            id: result.admin.id,
            email: result.admin.email,
            name: result.admin.firstname,
            token: result.admin.token,
          };
          localStorage.setItem("currentUser", JSON.stringify(storageObj));
         // this.currentUserSubject.next(storageObj);
          return admin;
        }),
        catchError(this.handleError)
      );
  }


  register(registerData: any): Observable<any> {
    let httpHeaders = new HttpHeaders();
    httpHeaders.append("Content-Type", "application/json");

    const httpOptions = {
      headers: httpHeaders,
    };
    return this.http
      .post<any>(this.apiURL + `/admins/register`, registerData, httpOptions)
      .pipe(
        map((user) => {
          console.log("user");
          console.log(user);

          let result = JSON.parse(JSON.stringify(user));
          localStorage.setItem("token", result.result.token);
          //let jwt = new JwtHelperService();
          // this.currentUser = jwt.decodeToken(localStorage.getItem("token"));
          this.isLogged = true;

          const storageObj = {
            id: result.result._id,
            uid: result.result.uid,
            email: result.result.email,
            firstname: result.result.firstname,
            lastname: result.result.firstname,
            fullname: result.result.firstname,
            token: result.result.token,
          };
          localStorage.setItem("currentUser", JSON.stringify(storageObj));
         // this.currentUserSubject.next(storageObj);
          return user;
        }),
        catchError(this.handleError)
      );
  }


  ///

  createHotelUser(userData: any): Observable<any> {
    let httpHeaders = new HttpHeaders();
    httpHeaders.append("Content-Type", "application/json");

    const httpOptions = {
      headers: httpHeaders,
    };
    return this.http
      .post<any>(this.apiURL + `/user/create`, userData, httpOptions)
      .pipe(
        map((user) => {

          return user;
        }),
        catchError(this.handleError)
      );
  }
  // Error handling
  handleError(error: any) {
    let errorMessage = { message: "UNKNOWN", code: 0 };
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = { message: error.error.message, code: -100 };
    } else {
      // Get server-side error
      errorMessage = { message: error.error.error.message, code: error.status };
    }
    return throwError(() => {
      return errorMessage;
    });
  }
}
