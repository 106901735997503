import { QuoteService } from "src/app/services/quote.service";
import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  SimpleChanges,
} from "@angular/core";

@Component({
  selector: "vc-upload",
  templateUrl: "./vc-upload.component.html",
  styleUrls: ["./vc-upload.component.scss"],
})
export class VcUploadComponent implements OnInit {
  @Input() quote: any;
  files: any[] = [];

  imageTypes = ["png", "jpg", "jpeg", "webp"];

  constructor(
    private quoteService: QuoteService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    for (let propName in changes) {
      let change = changes[propName];

      if (propName == "quote") {
        this.quote = change.currentValue;
        if (this.quote.attachments)
          this.quote.attachments.forEach((element) => {
            this.files.push({
              isImg: this.imageTypes.some((substring) =>
                element.includes(substring)
              ),
              name: element,
              size: "item.size",
              progress: 100,
              src: "https://api.theeighthstar.com/api/" + element,
            });
          });
      }
    }
  }

  /**
   * on file drop handler
   */
  onFileDropped($event) {
    console.log("fileBrowseHandler");
    console.log($event);
    this.prepareFilesList($event);
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(e) {
    console.log("fileBrowseHandler");
    console.log(e.files);
    this.prepareFilesList(e.files);
  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile(index: number) {
    this.quoteService
      .deleteQuoteFile({ filename: this.files[index].name }, this.quote.id)
      .subscribe((res) => {
        this.files.splice(index, 1);
      });
  }

  /**
   * Simulate the upload process
   */
  uploadFilesSimulator(index: number) {
    /*setTimeout(() => {
      if (index === this.files.length) {
        return;
      } else {
        const progressInterval = setInterval(() => {
          if (this.files[index].progress === 100) {
            clearInterval(progressInterval);
            this.uploadFilesSimulator(index + 1);
          } else {
            this.files[index].progress += 5;
          }
        }, 200);
      }
    }, 1000);*/
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList(files: Array<any>) {
    let submitData = new FormData();

    for (const item of files) {
      item.progress = 0;
      this.files.push({
        isImg: false,
        name: item.name,
        size: item.size,
        progress: item.progress,
        src: "",
      });
      submitData.append("files", item);
    }
    console.log(this.files);

    this.quoteService
      .uploadQuoteFiles(submitData, this.quote.id)
      .subscribe((res) => {
        if (res) {
          console.log(res);

          this.files = [];
          res.result.attachments.forEach((element) => {
            this.files.push({
              isImg: this.imageTypes.some((substring) =>
                element.includes(substring)
              ),
              name: element,
              size: "item.size",
              progress: 100,
              src: "https://api.theeighthstar.com/api/" + element,
            });
          });

          this.cd.markForCheck();
        }
      });
  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) {
      return "0 Bytes";
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }
}
