import { RequestService } from "src/app/services/eg/request.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Component, Input, OnInit } from "@angular/core";
import {
  NavigationItem,
  NavigationLink,
} from "../../interfaces/navigation-item.interface";
import { filter, map, startWith } from "rxjs/operators";
import {
  ActivatedRoute,
  NavigationEnd,
  ParamMap,
  Router,
} from "@angular/router";
import { NavigationService } from "../../services/navigation.service";
import { trackByRoute } from "../../utils/track-by";

@UntilDestroy()
@Component({
  selector: "vex-navigation-item",
  templateUrl: "./navigation-item.component.html",
  styleUrls: ["./navigation-item.component.scss"],
})
export class NavigationItemComponent implements OnInit {
  @Input() item: NavigationItem;
  matBadgeCount = "";
  isActive$ = this.router.events.pipe(
    filter((event) => event instanceof NavigationEnd),
    startWith(null),
    map(() => (item: NavigationItem) => this.hasActiveChilds(item))
  );

  isLink = this.navigationService.isLink;
  isDropdown = this.navigationService.isDropdown;
  isSubheading = this.navigationService.isSubheading;
  trackByRoute = trackByRoute;

  constructor(
    private navigationService: NavigationService,
    private router: Router,
    private route: ActivatedRoute,
    private requestService: RequestService
  ) {}

  ngOnInit() {
    if (this.isLink(this.item) && !this.isFunction(this.item.route))
      this.requestService
        .getRequestsCountByService(this.item.label.toLowerCase())
        .subscribe((res) => {
          console.log(
            "getRequestsCountByService " + this.item.label.toLowerCase()
          );
          console.log(res);
          if (res != undefined && res > 0) this.matBadgeCount = "" + res;
        });
  }

  hasActiveChilds(parent: NavigationItem): boolean {
    if (this.isLink(parent)) {
      return this.router.isActive(parent.route as string, true);
    }

    if (this.isDropdown(parent) || this.isSubheading(parent)) {
      return parent.children.some((child) => {
        if (this.isDropdown(child)) {
          return this.hasActiveChilds(child);
        }

        if (this.isLink(child) && !this.isFunction(child.route)) {
          return this.router.isActive(child.route as string, true);
        }

        return false;
      });
    }

    return false;
  }

  isFunction(prop: NavigationLink["route"]) {
    return prop instanceof Function;
  }
}
