import { RestApiService } from '../rest-api.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DateTime } from 'luxon';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { Request } from '../../models/eg/request.interface';

@Injectable({
  providedIn: 'root'
})
export class RequestService extends RestApiService{

  constructor(public http: HttpClient) {
    super(http);
  }
  requests = new BehaviorSubject<Request[]>([]);//fakeRequests
  requests$ = this.requests.asObservable();

  filterValue = new BehaviorSubject<string>(null);
  filterValue$ = this.filterValue.asObservable();

  filteredRequests$ = combineLatest(
    this.requests$,
    this.filterValue$
  ).pipe(
    map(([requests, filterValue]) => filterValue ? requests?.filter(request => JSON.stringify(request).toLowerCase().includes(filterValue?.toLowerCase())) : requests)
  );


  markRequestAsRead(requestId: Request['id']) {
    const request = this.getRequestById(requestId);

    if (request.read) {
      return;
    }

    this.updateRequest(requestId, {
      read: true
    });
  }

  updateRequest(requestId: Request['id'], update: Partial<Request>) {
    const requests = [
      ...this.requests.getValue().filter(m => m.id !== requestId),
      {
        ...this.getRequestById(requestId),
        ...update
      }
    ];

    this.requests.next(requests);

  }

  setRequestById(quoteId, update) {
    return this.http
      .patch<any>(this.apiURL + `/requests/update/`+quoteId, update)
      .pipe();
  }


  getRequestsByQuoteId(quoteId) {
    return this.http
      .get<any>(this.apiURL + `/request/quote/`+quoteId)
      .pipe();
  }

  getMyRequests(requestFormat, requestStatus) {
    return this.http
      .get<any>(this.apiURL + `/requests/filter/`+requestFormat+`/`+requestStatus)
      .pipe();
  }

  getRequestsCount(format, status) {
    return this.http
      .get<any>(this.apiURL + `/requests/count/`+format+`/`+status)
      .pipe();
  }

  getRequestsCountByService(status) {
    return this.http
      .get<any>(this.apiURL + `/requests/service/count/`+status)
      .pipe();
  }

  getRequestById(requestId: Request['id']) {
    return this.requests.getValue().find(m => m.id === requestId);
  }
}
