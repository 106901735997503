<div class="container" style="background: #201e43;">
  <div class="flex-auto flex items-center  vc-container" >
    <a class="action flex-none"
       color="primary"
       href="#"
       id="get-vex"
       mat-flat-button>
       <img alt="Logo" class="w-32 select-none" src="assets/img/uploads/ eightstart_bg.png"/>
    </a>
    <div class="font-medium txtw hidden sm:block">
      <a href="#">CGU</a>
    </div>
    <div class="font-medium txtw hidden sm:block">
      <a href="#">Politique de Confidentialité</a>
    </div>
    <div class="font-medium txtw hidden sm:block">
      <a href="#">Besoin d'aide ?</a>
    </div>
    <div class="font-medium txtw hidden sm:block">
      <a href="#" class="flex align-center">
        <mat-icon svgIcon="mat:star"></mat-icon>&nbsp; Avis Clients
      </a>
    </div>
  </div>
</div>
